import * as React from "react";
import { Routes, Route } from "react-router-dom";
import SignUp from "../views/SignUp";
import OtpPage from "../views/OtpPage";
import Successful from "../views/Successful";
import TermsOfService from "../views/TermsOfService";

// import App from "../App";
// import ErrorPage from "../views/ErrorPage";

export default function RouteList() {
  return (
    <>
      <Routes>
        <Route path="/" element={<SignUp />} />
        <Route path="OtpPage/Successful" element={<Successful />} />
        <Route path="OtpPage" element={<OtpPage />} />
        <Route path="TermsOfService" element={<TermsOfService />} />
        {/* <Route path="campaignDetail" element={<CampaignDetail />} /> */}
        {/* <Route path="*" element={<ErrorPage />} /> */}
      </Routes>
    </>
  );
}
