import { ThreeDots } from "react-loader-spinner";
import Grid from "@mui/material/Grid";
import COLOR from "../assets/css/Color";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loader(props) {
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.visible}
      >
        <CircularProgress color="inherit" size={80} thickness={5} />
      </Backdrop>
    </>
  );
}
