import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import COLOR from "../assets/css/Color";
import API from "../api";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser"; // Import html-react-parser

let api = new API();

export default function GoalprizeInformationDetailView(props) {
  const { state } = useLocation();

  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    getTermsOfService();
  }, []);

  function getTermsOfService() {
    api
      .getTermsCondition()
      .then((res) => {
        if (res.data.status === 200) {
          setSelectedTab(res.data.response.text);
        } else if (res.status === 400) {
          console.log(res);
        } else {
          console.log(res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const replaceFont = (node) => {
    if (node.type === "text") {
      return node.data;
    } else if (node.type === "tag" && node.name === "font") {
      return (
        <span style={{ fontSize: "18px" }}>
          {node.children.map((child) => replaceFont(child))}
        </span>
      );
    }
  };

  return (
    <>
      <Grid container justifyContent={"center"}>
        <Card
          sx={{
            width: {
              xl: "60%",
              lg: "60%",
              md: "90%",
              sm: "90%",
              xs: "100%",
            },
          }}
        >
          {/*  <CardHeader
            title={"Goalprize Terms of Service (GTS)"}
            align="center"
            disableTypography={true}
            sx={styles.cardTitleText}
          /> */}
          <CardContent sx={styles.cardBodyText}>
            {selectedTab && parse(selectedTab, { replace: replaceFont })}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

const styles = {
  cardTitleText: {
    fontSize: {
      lg: 42,
      md: 42,
      sm: 42,
      xs: 42,
    },
    fontFamily: "Helvetica Neue",
    color: COLOR.BLACK,
  },

  cardBodyText: {
    fontSize: "inherit", // Let the font size inherit from parent
    fontFamily: "Helvetica Neue",
    color: "black",
  },
};
