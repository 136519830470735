export const U_BASE = process.env.REACT_APP_BASE_URL;
/* export const U_BASE =
  "https://e98b-2400-1a00-b060-4b90-ba4a-5f43-dd5b-2333.ngrok-free.app/"; */
export const U_TERMS_AND_CONDTION = "terms-conditions";
export const U_PRIVACY_POLICY = "data-policy";
export const U_REFUND_POLICY = "rules-details";
export const U_HOW_TO_WIN = "how-to-win";
export const U_FAQ = "faq";
export const U_BUSINESS_PARTNER = "business-partner";
export const AS_USER_TOKEN = "user_access_token";
export const U_SIGNUP = "v2/auth/business/signup";
export const U_GENERATE_OTP = "generate-otp/";
export const U_VERIFY_OTP = "v2/verify-otp/";
