import axios from "axios";
import {
  U_TERMS_AND_CONDTION,
  U_BASE,
  U_SIGNUP,
  U_GENERATE_OTP,
  U_VERIFY_OTP,
} from "../commonView/Constants";

const headers = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  const lang = (await localStorage.getItem("language")) || "en";
  headers.lang = lang;

  return headers;
};

const request = async (method, path, body) => {
  const url = `${U_BASE}${path}`;
  const headersObj = await headers();
  const options = {
    method,
    url,
    headers: headersObj,
  };
  if (body) {
    options.data = body;
  }
  // console.log("options are:-", options, body);

  return axios(options);
};

export default class API {
  getTermsCondition(data) {
    return request("POST", U_TERMS_AND_CONDTION, data);
  }

  signupAPI(data) {
    return request("POST", U_SIGNUP, data);
  }

  generateOtp(data) {
    return request("POST", U_GENERATE_OTP + data);
  }

  verifyOtp(data) {
    return request("POST", U_VERIFY_OTP, data);
  }
}
