import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import logo from "../assets/img/goalprize_logo.png";
import COLOR from "../assets/css/Color.js";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useNavigate, useLocation } from "react-router-dom";
import SnackBar from "../components/Snackbar.js";
import Loader from "../components/Loader.js";
import { useTranslation } from "react-i18next";

import API from "../api/index.js";

let api = new API();

// import { useNavigate } from "react-router-dom";

export default function OtpPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [otp, setOtp] = useState("");
  const [otpResend, setotpResend] = useState(null);
  const [errorChecked, setErrorChecked] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  useEffect(() => {
    // Set the language when the component mounts
    const storedLanguage = localStorage.getItem("language");
    i18n.changeLanguage(storedLanguage || "en");
  }, [i18n]);

  const otpHandleChange = (newValue) => {
    setOtp(newValue);
  };

  const onVerifyClick = () => {
    if (otp.length < 3) {
      setErrorChecked(true);
    } else {
      setErrorChecked(false);
      setIsLoading(true);
      let data = JSON.stringify({
        email: state,
        otp: otp,
        type: 1,
      });
      api
        .verifyOtp(data)
        .then((json) => {
          if (json.data.status == 200) {
            navigate("Successful", {});
          } else if (json.data.status == 400) {
            setErrorChecked(true);
            setIsLoading(false);
          } else {
            setErrorChecked(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setErrorChecked(true);
          setIsLoading(false);
          console.log("Error", error);
        });
    }
  };

  const onResendClick = () => {
    setIsLoading(true);
    api
      .generateOtp(state)
      .then((json) => {
        setIsLoading(false);

        if (json.data.status == 200) {
          setIsLoading(false);
          setotpResend(true);
        } else if (json.data.status == 400) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Loader visible={isLoading} />
        <SnackBar
          open={otpResend}
          message={t("resent_OTP")}
          onClose={() => setotpResend(false)}
          severity={"success"}
        />
        <SnackBar
          open={errorChecked}
          message={t("enter_valid_OTP")}
          onClose={() => setErrorChecked(false)}
          severity={"error"}
        />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 75,
              width: 300,
            }}
            src={logo}
          />
          <Typography component="h2" variant="h8" sx={{ mt: 5 }}>
            {t("verify_email")}
          </Typography>
          <Typography component="h5" sx={{ mt: 1, alignSelf: "center" }}>
            {t("enter_OTP")}
            <br />
            {state}
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <MuiOtpInput value={otp} onChange={otpHandleChange} />

            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: COLOR.GP_BLUE }}
              onClick={onVerifyClick}
            >
              Verify
            </Button>
          </Box>
          <Button
            sx={{ color: COLOR.BLACK, textDecoration: "underline" }}
            onClick={onResendClick}
          >
            {t("resend_OTP")}
          </Button>
        </Box>
      </Container>
    </>
  );
}
