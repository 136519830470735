import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import tick from "../assets/img/tick.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Successful() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Set the language when the component mounts
    const storedLanguage = localStorage.getItem("language");
    i18n.changeLanguage(storedLanguage || "en");
  }, [i18n]);
  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 30,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 150,
              width: 150,
            }}
            src={tick}
          />
          <Typography component="h2" variant="h7" sx={{ mt: 5 }}>
            {t("successful_created")}
          </Typography>
        </Box>
      </Container>
    </>
  );
}
